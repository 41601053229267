.app {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
